import { Undraw404 } from "../components/icons";

//TODO: Add return to home link
export default function Custom404() {
  return (
    <div>
      <Undraw404 className="w-1/2 h-auto m-auto pt-28 animate-fadeIn" />
    </div>
  );
}
